<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="设置" />
    <van-uploader class="header" name="Photo" :preview-image="false" :deletable="false" :after-read="handleUploadHeader">
      <div class="header-item">
        <div class="img img-cover">
          <img v-if="userInfo.Header" :src="userInfo.Header" />
          <img v-else src="../../assets/images/default-header.png" />
        </div>
        <img class="xj" src="../../assets/images/xj-icon.png" />
      </div>
    </van-uploader>
    <div class="column-item">
      <router-link class="item" :to="{path: '/resetNickname'}">
        <div class="name">昵称</div>
        <div class="detail flex-1">
          <span v-if="userInfo.Nickname">{{ userInfo.Nickname }}</span>
          <span v-else class="gray">请输入昵称</span>
        </div>
        <div class="icon img-cover"><img src="../../assets/images/right-icon-gray.png" /></div>
      </router-link>
      <router-link class="item" :to="{path: '/resetPhone'}">
        <div class="name">手机号</div>
        <div class="detail flex-1">{{ userInfo.Phone }}</div>
        <div class="icon img-cover"><img src="../../assets/images/right-icon-gray.png" /></div>
      </router-link>
      <div class="item">
        <div class="name">ID</div>
        <div class="detail flex-1">{{ userInfo.Id }}</div>
        <div class="icon img-cover"></div>
      </div>
    </div>
    <div class="column-item">
      <router-link class="item" :to="{path: '/resetPwd'}">
        <div class="name">重置密码</div>
        <div class="icon img-cover"><img src="../../assets/images/right-icon-gray.png" /></div>
      </router-link>
    </div>
    <div class="flex-1"></div>
    <div class="logout-btn" @click="showLogout = true">退出登录</div>

    <van-popup v-model="showLogout" position="center" style="border-radius: .16rem;">
      <div class="mobile-logout-container">
        <div class="title">温馨提示</div>
        <div class="detail">确认要退出登录吗？</div>
        <div class="btn-item">
          <div class="btn" @click="showLogout = false">取消</div>
          <div class="btn sure" @click="handleLogout">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "Setting",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      showLogout: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {

  },
  methods: {
    ...mapActions(['GetUserInfo']),
    async handleUploadHeader(file) {
      var res = await this.$API.upload.post(file[0])
      if (res.error == 0) {
        this.handleEditUserInfo('Header', res.info)
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    },
    async handleEditUserInfo(key, value) {
      var res = await this.$API.userInfo.post({
        Switch: 2,
        [key]: value
      })
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.GetUserInfo()
        return true
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
        return false
      }
    },
    handleLogout() {
      this.$TOOL.data.clear()
      this.$router.replace('/')
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.header {
  /deep/.van-uploader__wrapper {
    margin-left: 0;
  }
}
.header-item {
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  margin-top: .6rem;
  margin-bottom: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .8rem;
  .img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  .xj {
    width: .44rem;
    height: .44rem;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.column-item {
  width: 6.38rem;
  padding: 0 .24rem;
  border-radius: .16rem;
  background-color: #fff;
  margin-bottom: .2rem;
  .item {
    width: 100%;
    height: .88rem;
    border-bottom: .02rem solid #f5f5fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .28rem;
    &:nth-last-child(1) {
      border: none;
    }
    .name {
      color: #242941;
    }
    .detail {
      text-align: right;
      color: #bcbdc5;
    }
    .icon {
      margin-left: .12rem;
      width: .2rem;
      height: .2rem;
    }
  }
}
.logout-btn {
  cursor: pointer;
  width: 6.86rem;
  height: .88rem;
  margin-bottom: 1.6rem;
  border-radius: .12rem;
  background-color: #dde1e6;
  font-size: .3rem;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-logout-container {
  width: 5rem;
  padding: .4rem;
  text-align: center;
  .title {
    line-height: .48rem;
    color: #11113a;
    font-size: .32rem;
    font-weight: bold;
  }
  .detail {
    margin-top: .28rem;
    color: #bcbdc5;
    line-height: .4rem;
    font-size: .28rem;
  }
  .btn-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .72rem;
    .btn {
      cursor: pointer;
      width: 2.4rem;
      height: .8rem;
      border-radius: .12rem;
      background-color: #bac0cc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: .24rem;
      &.sure {
        background-color: #1399ff;
      }
    }
  }
}
</style>